<template>
	<div class="d-flex flex-column w-100">
		<div>
			<b-form-group :label="label">
				<b-form-textarea
					:ref="refTextArea"
					:id="id"
					v-model="dataTextArea"
					:placeholder="placeholder"
					:rows="rows"
					:max-rows="maxRows"
					@click.prevent.stop="handleClick"
					@keyup="handleKeyUp"
				/>
			</b-form-group>
			<context-menu-custom-for-text-area
				:menuX="menuX"
				:menuY="menuY"
				:showContextMenu="isShowContextMenu"
				title="Variables"
				:dataList="variableList"
				@on-close-context-menu="CloseContextMenu"
				@item:clicked="selectedArea"
			/>
		</div>
	</div>
</template>
<script>
import ContextMenuCustomForTextArea from './ContextMenuCustomForTextArea';
import { getCarbonVariables } from '@/cruds/language.crud';
export default {
	name: 'CustomTextArea',
	components: { ContextMenuCustomForTextArea },
	data: () => ({
		dataTextArea: '',
		menuX: 0,
		menuY: 0,
		isShowContextMenu: false,
		variableList: [],
		cursor: 0
	}),
	props: {
		id: {
			type: String | Number,
			required: false,
			default: Math.random()
		},
		refTextArea: {
			type: String,
			required: false,
			default: ''
		},
		refAllUseTextArea: {
			type: Array,
			required: false,
			default: () => []
		},
		label: {
			type: String,
			required: false,
			default: ''
		},
		placeholder: {
			type: String,
			required: false,
			default: ''
		},
		keyItem: {
			type: String,
			required: false,
			default: ''
		},
		rows: {
			type: Number,
			required: false,
			default: 3
		},
		maxRows: {
			type: Number,
			required: false,
			default: 3
		},
		model: {
			type: String,
			required: false,
			default: ''
		}
	},
	watch: {
		model: {
			handler(newVal) {
				this.dataTextArea = newVal;
				this.$emit('set-model-value', { dataTextArea: this.dataTextArea, keyItem: this.keyItem });
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		this.initialize();
	},
	methods: {
		initialize() {
			getCarbonVariables().then((res) => {
				this.variableList = res.map((item) => item.name);
			});
		},
		isCheckBalise(text) {
			const _text = text.toString();
			if (/.+<<$/gi.test(_text)) {
				if (/<<$/.test(_text)) {
					return true;
				}
				return false;
			} else {
				if (/<<$/gi.test(_text)) return true;
				return false;
			}
		},
		CloseContextMenu() {
			this.isShowContextMenu = false;
		},
		selectedArea(text) {
			const name = `${text}>>`;
			this.dataTextArea = this.dataTextArea.slice(0, this.cursor) + name + this.dataTextArea.slice(this.cursor);
			this.$emit('set-model-value', { dataTextArea: this.dataTextArea, keyItem: this.keyItem });
			this.isShowContextMenu = false;
			this.menuX = 0;
			this.menuY = 0;
		},
		onCloseLastAction() {
			this.$emit('refresh-emit-text-area-refs');
			setTimeout(() => {
				const refsComponents = this.refAllUseTextArea.find((item) => item.isShowContextMenu === true);
				if (refsComponents) {
					const { name, refs } = refsComponents;
					if (name !== this.refTextArea) {
						refs.CloseContextMenu();
					}
				}
			}, 100);
		},
		handleClick(event) {
			const textAreaRef = this.refTextArea;
			const textArea = this.$refs[textAreaRef].$el;
			const contentTextArea = event.target.value;
			const contentArrays = contentTextArea.split('\n');
			this.cursor = textArea.selectionStart;
			const indexLigne = this.getLineNumber(textArea, this.cursor);
			const text = contentArrays[indexLigne - 1];
			const balise = this.isCheckBalise(text);
			this.onCloseLastAction();
			if (balise) {
				this.menuY = indexLigne * 20;
				this.isShowContextMenu = true;
			} else {
				this.isShowContextMenu = false;
			}
		},
		handleKeyUp(event) {
			const textAreaRef = this.refTextArea;
			const textArea = this.$refs[textAreaRef];
			const contentTextArea = event.target.value.toString();
			const contentArrays = contentTextArea.split('\n');
			this.cursor = textArea.selectionStart;
			const textAreaElement = textArea.$el;
			const indexLigne = this.getLineNumber(textAreaElement, this.cursor) - 1;
			let balise = false;
			this.$emit('set-model-value', { dataTextArea: this.dataTextArea, keyItem: this.keyItem });
			if (this.cursor > 0) {
				const text = contentArrays[indexLigne].substring(0, this.cursor);
				balise = this.isCheckBalise(text);
			} else {
				balise = false;
			}
			if (balise) {
				let ligne = 0;
				let _menuY = 0;
				if (indexLigne <= this.maxRows) {
					ligne = indexLigne * 20;
					_menuY = 20;
				} else {
					ligne = this.maxRows * 20;
					_menuY = 0;
				}
				const _menuX = contentArrays[indexLigne].length + 1 || 0;
				this.menuX = _menuX * 8;
				const clientWidth = textArea.$el.clientWidth;
				const pourcentage = (clientWidth * 16) / 100;
				if (this.menuX >= clientWidth) {
					this.menuX = this.menuX - pourcentage;
				}
				if (this.menuX + pourcentage >= clientWidth) {
					this.menuX = (clientWidth * 80) / 100;
				}
				this.menuY = _menuY + ligne;
				this.isShowContextMenu = true;
			} else {
				this.isShowContextMenu = false;
			}
		},
		getLineNumber(textarea, cursorPosition) {
			const textBeforeCursor = textarea.value.slice(0, cursorPosition);
			return textBeforeCursor.split('\n').length;
		}
	}
};
</script>
<style lang="scss" scoped>
.text-area-placement {
	display: 'flex';
	position: 'relative';
	height: '100%';
}
</style>
