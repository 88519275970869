var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showContextMenu
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.handleOutside,
              expression: "handleOutside",
            },
          ],
          staticClass: "context-menu-translation",
          style: { top: _vm.menuY + 26 + "px", left: _vm.menuX + "px" },
        },
        [
          _c("div", { staticClass: "key-word-header" }, [
            _vm.activeFilter
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row align-items-center justify-content-center text-center w-100",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-start flex-grow-0",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn-transparent text-color-rhapsody-in-blue",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.handleActiveSearch.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(_vm.getLucideIcon("ArrowLeft"), {
                              tag: "component",
                              attrs: { color: "#225CBD", size: 22 },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-start mr-2 flex-grow-1",
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            size: "sm",
                            placeholder: _vm.FormMSG(3, "Enter your text"),
                          },
                          on: { keyup: _vm.onSearch },
                          model: {
                            value: _vm.textSearch,
                            callback: function ($$v) {
                              _vm.textSearch = $$v
                            },
                            expression: "textSearch",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center text-center w-100",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center ml-4 flex-grow-1",
                      },
                      [
                        _c("h6", { staticClass: "text-title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-end flex-grow-0",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn-transparent text-color-rhapsody-in-blue",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.handleActiveSearch.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(_vm.getLucideIcon("Search"), {
                              tag: "component",
                              attrs: { color: "#225CBD", size: 22 },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
          ]),
          _vm.mapList.length !== 0
            ? _c(
                "div",
                { staticClass: "key-word-body" },
                _vm._l(_vm.mapList, function (data, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "py-1 context-menu-items-keyword cursor-pointer",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.clickContextMenu(data)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t" + _vm._s(data) + "\n\t\t")]
                  )
                }),
                0
              )
            : _c("div", { staticClass: "key-word-body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center py-1",
                  },
                  [_vm._v(_vm._s(_vm.FormMSG(2, "Empty data")))]
                ),
              ]),
          _c(
            "div",
            {
              staticClass: "key-word-footer",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.onClose()
                },
              },
            },
            [
              _c("div", { staticClass: "close-context" }, [
                _vm._v(_vm._s(_vm.FormMSG(1, "Close"))),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }