<template>
	<div class="context-menu-translation" :style="{ top: menuY + 26 + 'px', left: menuX + 'px' }" v-if="showContextMenu" v-click-outside="handleOutside">
		<div class="key-word-header">
			<div v-if="activeFilter" class="d-flex flex-row align-items-center justify-content-center text-center w-100">
				<div class="d-flex align-items-center justify-content-start flex-grow-0">
					<button class="btn-transparent text-color-rhapsody-in-blue" @click.prevent.stop="handleActiveSearch">
						<component :is="getLucideIcon('ArrowLeft')" color="#225CBD" :size="22" />
					</button>
				</div>
				<div class="d-flex align-items-center justify-content-start mr-2 flex-grow-1">
					<b-form-input size="sm" @keyup="onSearch" v-model="textSearch" :placeholder="FormMSG(3, 'Enter your text')"></b-form-input>
				</div>
			</div>
			<div v-else class="d-flex align-items-center justify-content-center text-center w-100">
				<div class="d-flex align-items-center justify-content-center ml-4 flex-grow-1">
					<h6 class="text-title">{{ title }}</h6>
				</div>
				<div class="d-flex align-items-center justify-content-end flex-grow-0">
					<button class="btn-transparent text-color-rhapsody-in-blue" @click.prevent.stop="handleActiveSearch">
						<component :is="getLucideIcon('Search')" color="#225CBD" :size="22" />
					</button>
				</div>
			</div>
		</div>
		<div class="key-word-body" v-if="mapList.length !== 0">
			<div
				class="py-1 context-menu-items-keyword cursor-pointer"
				v-for="(data, index) in mapList"
				@click.prevent.stop="clickContextMenu(data)"
				:key="index"
			>
				{{ data }}
			</div>
		</div>
		<div class="key-word-body" v-else>
			<div class="d-flex align-items-center justify-content-center py-1">{{ FormMSG(2, 'Empty data') }}</div>
		</div>
		<div class="key-word-footer" @click.prevent.stop="onClose()">
			<div class="close-context">{{ FormMSG(1, 'Close') }}</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import ClickOutside from 'vue-click-outside';
export default {
	name: 'ContextMenuCustomForTextArea',
	mixins: [languageMessages, globalMixin],
	directives: {
		ClickOutside
	},
	data: () => ({
		activeFilter: false,
		textSearch: '',
		mapList: []
	}),
	props: {
		title: {
			type: String,
			default: ''
		},
		dataList: {
			type: Array,
			default: []
		},
		showContextMenu: {
			type: Boolean,
			default: false
		},
		menuX: {
			type: Number,
			default: 0
		},
		menuY: {
			type: Number,
			default: 0
		}
	},
	watch: {
		showContextMenu: {
			handler(newVal) {
				if (newVal) {
					this.mapList = this.dataList;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.mapList = this.dataList;
		},
		handleOutside() {
			this.onClose();
		},
		onSearch(event) {
			const text = event.target.value.toString().trim().toUpperCase();
			if (text.length > 0) {
				this.mapList = this.dataList.filter((_text) => {
					if (_text.toString().toUpperCase().includes(text)) {
						return true;
					}
					return false;
				});
			} else {
				this.mapList = this.dataList;
			}
		},
		handleActiveSearch() {
			this.activeFilter = !this.activeFilter;
		},
		onClose() {
			this.$emit('on-close-context-menu');
			this.activeFilter = false;
			this.textSearch = '';
		},
		clickContextMenu(data) {
			this.$emit('item:clicked', data);
		}
	}
};
</script>
<style scoped>
.context-menu-translation {
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: center;
	justify-content: center;
	width: 250px;
	position: absolute;
	background-color: #ffffff;
	border-radius: calc(0.3rem - 1px);
	border: 0.5px solid rgb(213, 213, 213);
	z-index: 10;
}
.key-word-header {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	border-bottom: 1px solid rgb(203, 203, 203);
	border: 0.5px solid rgb(213, 213, 213);
	width: 250px;
	height: 36px;
}
.key-word-body {
	width: 250px;
	height: 100px;
	overflow: auto;
}
.key-word-footer {
	width: 250px;
	background-color: #f5f7f9;
}

.title-item {
	width: auto;
	background-color: #ffffff;
	z-index: 200;
	position: sticky;
	top: -10px;
}
.close-context {
	display: flex;
	z-index: 200;
	cursor: pointer;
	width: 100%;
	border-radius: 0 0 2px 2px;
	margin-bottom: -4px;
	height: 28px;
	align-items: center;
	justify-content: center;
	border: 0.5px solid rgb(213, 213, 213);
	background-color: #f5f7f9;
	color: rgba(6, 38, 62, 0.84);
	font-weight: 600;
}

.context-menu-items-keyword {
	padding-left: 10px;
}

.context-menu-items-keyword:hover {
	background-color: #b8e4e3;
}

.text-title {
	display: flex;
	margin-top: 6px;
	font-size: 14px;
	color: rgba(6, 38, 62, 0.84);
}

::-webkit-scrollbar {
	width: 5px;
	background: #eee;
}
::-webkit-scrollbar:horizontal {
	height: 10px;
}
::-webkit-scrollbar-button {
	width: 5px;
	height: 5px;
	background: #666;
}
::-webkit-scrollbar-piece {
	background: #eee;
}
::-webkit-scrollbar-piece:start {
	background: #eee;
}
::-webkit-scrollbar-thumb {
	background: #333;
}
::-webkit-scrollbar-corner {
	background: #333;
}
</style>
