var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "viewLogAutomationDialog",
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(45, "Log"),
        size: "xl",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "cancel-variant": "outline-primary",
        "ok-only": "",
        "ok-variant": "primary",
        "ok-title": _vm.FormMSG(569, "Close"),
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.emitEventClose.apply(null, arguments)
        },
        hidden: _vm.emitEventClose,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "md",
                        variant: "custom-outline-gray",
                        block: "",
                      },
                      on: { click: ok },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(43, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "container-layout form" },
        [
          _c("div", { staticClass: "fs-16 fw-700 mb-2" }, [
            _vm._v(_vm._s(_vm.FormMSG(514, "Summary"))),
          ]),
          _c("div", { staticClass: "d-flex mb-2" }, [
            _c("div", { staticClass: "fs-14 fw-500" }, [
              _vm._v(_vm._s(_vm.FormMSG(515, "Number of entries processed:"))),
            ]),
            _c("div", { staticClass: "fs-14 fw-700" }, [
              _vm._v(_vm._s(_vm.nbProcessed)),
            ]),
          ]),
          _c("div", { staticClass: "d-flex mb-2" }, [
            _c("div", { staticClass: "fs-14 fw-500" }, [
              _vm._v(_vm._s(_vm.FormMSG(516, "Number of entries updated:"))),
            ]),
            _c("div", { staticClass: "fs-14 fw-700" }, [
              _vm._v(_vm._s(_vm.nbUpdate)),
            ]),
          ]),
          _c("div", { staticClass: "d-flex mb-3" }, [
            _c("div", { staticClass: "fs-14 fw-500" }, [
              _vm._v(
                _vm._s(_vm.FormMSG(517, "Number of entries not updated:"))
              ),
            ]),
            _c("div", { staticClass: "fs-14 fw-700" }, [
              _vm._v(_vm._s(_vm.nbNotUpdate)),
            ]),
          ]),
          _c(
            "b-row",
            { staticClass: "mb-2" },
            [
              _c("b-col", { attrs: { cols: "8" } }, [
                _c("div", { staticClass: "d-flex" }, [
                  _c("div", { staticClass: "fs-16 fw-700" }, [
                    _vm._v(_vm._s(_vm.FormMSG(518, "Details"))),
                  ]),
                  _c("div", { staticClass: "fs-16 ml-2" }, [
                    _vm._v(_vm._s(_vm.logStr === "" ? " - " : _vm.logStr)),
                  ]),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "primary" },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(519, "Export detailed report")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _vm.$screen.width >= 992
                    ? _c("b-table", {
                        ref: "documentPackageTable",
                        staticStyle: { "text-align": "left" },
                        attrs: {
                          "selected-variant": "primary",
                          hover: "",
                          selectable: "",
                          "select-mode": "single",
                          responsive: "sm",
                          "sticky-header": "500px",
                          items: _vm.dataList,
                          fields: _vm.greenTableFields,
                          bordered: "",
                          striped: "",
                          small: "",
                          "head-variant": "dark",
                          "empty-text": _vm.FormMSG(250, "No data found"),
                          "show-empty": "",
                          "tbody-tr-class": "p-2",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(date)",
                              fn: function ({ item }) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            item.date,
                                            "DD/MM/YYYY"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(amount)",
                              fn: function ({ item }) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm._f("formatCurrency")(item.amount)
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(coTwo)",
                              fn: function ({ item }) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm._f("formatRendKg")(item.coTwo)
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1565879555
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }