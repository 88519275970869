<template>
	<b-modal
		id="carbonTemplateDialog"
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="titleCaption"
		size="xl"
		no-close-on-backdrop
		no-close-on-esc
		cancel-variant="outline-primary"
		:ok-disabled="loadingSubmit"
		:cancel-disabled="loadingSubmit"
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@ok.prevent="handleOk"
	>
		<div ref="content-carbon-template-dialog-ref">
			<b-row>
				<b-col cols="5">
					<b-form-group :label="FormMSG(187, 'Name/Description (optional)')">
						<b-form-input v-model="dataToSend.name" />
					</b-form-group>
				</b-col>
				<b-col cols="7">
					<div class="float-right mt-4">
						<b-form-checkbox v-model="dataToSend.disable" switch size="lg">
							<div class="mt-1">{{ FormMSG(514, 'Disable') }}</div>
						</b-form-checkbox>
					</div>
				</b-col>
			</b-row>
			<b-alert show variant="info">
				<div class="d-flex">
					<div class="fs-16 fw-700 mr-1">
						{{ FormMSG(53, 'Notes:') }}
					</div>
					<div>
						{{
							FormMSG(
								56,
								'Notes: the combination of all criteria entered (AND logic) should be verified to apply the automation result on a green entry.'
							)
						}}
					</div>
				</div>
			</b-alert>
			<b-row>
				<b-col cols="5" style="border-right: 1px solid #cad0d7">
					<b-row>
						<b-col>
							<div class="fs-18 fw-700 text-center">
								{{ FormMSG(165, 'Criteria') }}
							</div>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group :label="FormMSG(158, 'Cost centers')">
								<b-form-textarea v-model="dataToSend.costCenters" :placeholder="FormMSG(195, 'Enter cost codes ...')" rows="3" max-rows="3" />
								<div class="invalid-feedback text-color-blue-streak">
									<component :is="getLucideIcon('Info')" :size="16" />
									{{ FormMSG(1241, 'Enter cost codes separate by a "," - For instance: 5311,53112,71111') }}
								</div>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<supplier-selector
								:title="FormMSG(250, 'Add supplier details')"
								:label="$screen.width > 576 ? FormMSG(481, 'Supplier') : ''"
								:placeholder="FormMSG(13, 'Filter by name')"
								:addlabel="FormMSG(5442, 'New supplier')"
								:update-label="FormMSG(40, 'Update')"
								:show-map="false"
								type="all"
								:supplier-id="dataToSend.supplierId"
								use-new-design
								version="1.0"
								class="mb-3"
								@change="handleSupplierChange"
								@supplier:unselected="handleSupplierChange"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<custom-text-area
								id="keyword-1"
								:ref="CUSTOM_TEXT_AREA_REFS.TEXT_AREA_KEYWORD_ONE"
								:refTextArea="CUSTOM_TEXT_AREA_REFS.TEXT_AREA_KEYWORD_ONE"
								:refAllUseTextArea="refAllUseTextArea"
								:label="FormMSG(168, 'Keyword 1')"
								:model="dataToSend.keyWordOne"
								:keyItem="'keyWordOne'"
								:placeholder="FormMSG(169, 'Enter a keyword ...')"
								:rows="3"
								:max-rows="3"
								@set-model-value="handleSetModelValue"
								@refresh-emit-text-area-refs="initializeRefs"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<custom-text-area
								id="keyword-2"
								:ref="CUSTOM_TEXT_AREA_REFS.TEXT_AREA_KEYWORD_TWO"
								:refTextArea="CUSTOM_TEXT_AREA_REFS.TEXT_AREA_KEYWORD_TWO"
								:refAllUseTextArea="refAllUseTextArea"
								:label="FormMSG(598, 'Keyword 2')"
								:model="dataToSend.keyWordTwo"
								:keyItem="'keyWordTwo'"
								:placeholder="FormMSG(169, 'Enter a keyword ...')"
								:rows="3"
								:max-rows="3"
								@set-model-value="handleSetModelValue"
								@refresh-emit-text-area-refs="initializeRefs"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<custom-text-area
								id="keyword-3"
								:ref="CUSTOM_TEXT_AREA_REFS.TEXT_AREA_KEYWORD_THREE"
								:refTextArea="CUSTOM_TEXT_AREA_REFS.TEXT_AREA_KEYWORD_THREE"
								:refAllUseTextArea="refAllUseTextArea"
								:label="FormMSG(599, 'Keyword 3')"
								:model="dataToSend.keyWordThree"
								:keyItem="'keyWordThree'"
								:placeholder="FormMSG(169, 'Enter a keyword ...')"
								:rows="3"
								:max-rows="3"
								@set-model-value="handleSetModelValue"
								@refresh-emit-text-area-refs="initializeRefs"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-checkbox v-model="dataToSend.searchInDescOnly" :value="true" :unchecked-value="false">
								{{ FormMSG(190, 'Search keyword only description') }}
							</b-form-checkbox>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="7">
					<b-row>
						<b-col>
							<div class="fs-18 fw-700 text-center">
								{{ FormMSG(166, 'Result') }}
							</div>
						</b-col>
					</b-row>
					<div class="d-flex justify-content-end mt-2">
						<div>
							<b-dropdown id="setCat" :text="labelSetCat" no-caret dropleft variant="success" size="sm">
								<b-dropdown-item @click="useSetCatInKeyword('keyWordOne')">{{ FormMSG(1021, 'Used as keyword 1') }}</b-dropdown-item>
								<b-dropdown-item @click="useSetCatInKeyword('keyWordTwo')">{{ FormMSG(1022, 'Used as keyword 2') }}</b-dropdown-item>
								<b-dropdown-item @click="useSetCatInKeyword('keyWordThree')">{{ FormMSG(1023, 'Used as keyword 3') }}</b-dropdown-item>
							</b-dropdown>
						</div>
					</div>
					<b-row>
						<b-col>
							<fieldset class="my-0 py-0 pb-0 mt-1" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
								<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
									{{ FormMSG(30, 'Green details') }}
								</legend>
								<div class="pt-3 pb-4">
									<co-two-clap
										ref="coTwoClapRef"
										:parent-id="0"
										:entity-type="9"
										:for-create="true"
										:is-submit="false"
										:external-data="isEdit === true ? dataToSend : {}"
										for-carbon-template
										@co-two-clap:change="handleCoTwoClapChange"
									/>
								</div>
							</fieldset>
						</b-col>
					</b-row>
					<b-row v-if="dataToSend.coTwoCategory === 2" class="mt-5">
						<b-col>
							<b-form-group :label="FormMSG(457, 'Average cost per night')">
								<b-input-group>
									<b-form-input v-model="dataToSend.unitAmountTotal" @change="handleChangeAverageCostPerNight" />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row v-if="showAverageCostPerKilometer" class="mt-5">
						<b-col>
							<b-form-group :label="FormMSG(458, 'Average cost per kilometer')">
								<b-input-group>
									<b-form-input
										v-model="dataToSend.unitAmountTotal"
										:disabled="dataToSend.boolValue"
										@change="handleChangeAverageTransport"
									/>
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row v-if="dataToSend.coTwoCategory === 12" class="mt-5">
						<b-col>
							<b-form-group :label="FormMSG(154, 'Average cost per km')">
								<b-input-group>
									<b-form-input v-model="dataToSend.unitAmountTotal" @change="handleChangeAverageTransportLodges" />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row v-if="dataToSend.coTwoCategory === 13" class="mt-5">
						<b-col>
							<b-form-group :label="FormMSG(139, 'Average cost per liter')">
								<b-input-group>
									<b-form-input v-model="dataToSend.unitAmountTotal" @change="handleChangeAverateFuel" />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row v-if="dataToSend.coTwoCategory === 3" class="mt-5">
						<b-col sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="FormMSG(868, 'Reason')">
								<b-form-select v-model="dataToSend.type" :options="reasonTypes" @change="handleChangeReason" />
							</b-form-group>
						</b-col>
						<b-col v-if="showSubTypeEnergy" sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="subTypeEnergyCaption">
								<v-select
									v-model="dataToSend.locationId"
									label="setName"
									:reduce="(option) => +option.id"
									:options="locationsPremisesList"
									:placeholder="FormMSG(915, 'Please select ...')"
								/>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row
						v-if="dataToSend.coTwoCategory === 3 || dataToSend.coTwoCategory === 1 || dataToSend.coTwoCategory === 15"
						:class="dataToSend.coTwoCategory !== 3 ? 'mt-5' : ''"
					>
						<b-col sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="FormMSG(571, 'Energy type')">
								<b-form-select v-model="dataToSend.subType" :options="energyTypeOptions" @change="handleChangeEnergyType" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="FormMSG(482, 'Average cost per unit')">
								<b-input-group>
									<b-form-input v-model="dataToSend.unitAmountTotal" @change="handleChangeAverageEnergy" />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row v-if="dataToSend.coTwoCategory === 5" class="mt-5">
						<b-col sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="FormMSG(807, 'Waste type')">
								<b-form-select v-model="dataToSend.subType" :options="wasteTypeOptions" @change="handleChangeWasteType" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="FormMSG(808, 'Average cost per kg')">
								<b-input-group>
									<b-form-input v-model="dataToSend.unitAmountTotal" @change="handleChangeAverageWaste" />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row v-if="dataToSend.coTwoCategory === 6" class="mt-5">
						<b-col sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="FormMSG(461, 'Meal type')">
								<b-form-select v-model="dataToSend.subType" :options="mealTypeOptions" @change="handleChangeMealType" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="FormMSG(462, 'Average cost per meal')">
								<b-input-group>
									<b-form-input v-model="dataToSend.unitAmountTotal" @change="handleChangeAverageMeal" />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row v-if="dataToSend.coTwoCategory === 10" class="mt-5">
						<b-col sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="FormMSG(125, 'Vehicle type')">
								<b-form-select v-model="dataToSend.type" :options="vehicleTypeOptions" />
							</b-form-group>
						</b-col>
						<b-col sm="12" md="6" lg="6" xl="6">
							<b-form-group :label="FormMSG(126, 'Average cost per unit')">
								<b-input-group>
									<b-form-input v-model="dataToSend.unitAmountTotal" @change="handleChangeAverateVehicleShooting" />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<div v-if="showRawTextEditor">
				<b-row class="mt-4">
					<b-col>
						<b-form-group class="mb-0">
							<label for="type" class="pb-0 mb-0">
								<div class="d-flex align-items-center fs-12">
									{{ FormMSG(189, 'Raw text') }}&nbsp;
									<info-tooltip style="margin-bottom: 2px">
										{{ FormMSG(644, 'Data coming from the creation by a document') }}
									</info-tooltip>
								</div>
							</label>
							<b-form-textarea disabled :value="rawTextData.text" rows="7" class="bg-color-white" />
						</b-form-group>
						<div v-if="!rawTextFileDownloaded" class="float-right">
							<button
								type="button"
								class="btn-transparent text-color-rhapsody-in-blue"
								v-b-tooltip.hover.left.html="FormMSG(521, 'Copy text')"
								@click="handleCopyRawText"
							>
								<component :is="getLucideIcon('Download')" :size="20" />
							</button>
						</div>
						<b-alert :show="rawTextFileDownloaded" variant="success">
							{{ FormMSG(619, 'Downloaded file successfully!') }}
						</b-alert>
					</b-col>
				</b-row>
			</div>
		</div>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="loadingSubmit" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">
							{{ isCopyAction === true ? FormMSG(87, 'Copy') : FormMSG(59, 'Save') }}
						</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import CoTwoClap from '../green/CoTwoClap.vue';
import SupplierSelector from '@/components/SupplierSelector';
import CurrencySvg from '@/components/Svg/Currency';
import { addUpdCarbonTemplate } from '@/cruds/carbon.crud';
import { store } from '@/store';
import InfoTooltip from '@/components/LocationService/InfoTooltip';
import _ from 'lodash';
import { getLocations } from '@/cruds/locations.crud';
import { getProjectLocationListForPremises } from '@/cruds/premises.crud';
import CustomTextArea from './CustomTextArea.vue';
import {
	CAR_LODGE_ENERGY_TYPE_MENU,
	MEAL_TYPE_MENU,
	ENERGY_TYPE_MENU,
	WASTE_STANDARD_TYPE_MENU,
	WASTE_DESTRUCTION_TYPE_MENU,
	REASON_ENERGY_TYPE_MENU,
	CARBON_PRODUCTION_LOGISTIC_TYPE,
	INIT_DATA_CARBON
} from '@/shared/constants';

const INIT_DATA_TO_SEND = {
	name: '',
	supplierId: 0,
	keyWordOne: '',
	keyWordTwo: '',
	keyWordThree: '',
	searchInDescOnly: true,
	coTwoCategory: 0,
	type: 0,
	subType: 0,
	additionalSubType: 0,
	vehiculeId: 0,
	reason: 0,
	km: 0,
	liter: 0,
	mcube: 0,
	kwh: 0,
	kg: 0,
	msquare: 0,
	number: 0,
	boolValue: false,
	mealRed: 0,
	mealWhite: 0,
	mealVege: 0,
	mealNotSpecified: 0,
	unitAmountTotal: 0,
	waterVolumeUsed: 0,
	costCenters: '',
	disable: false,
	locationId: null,
	productionPhase: 0,
	typeMeasurement: 1,
	navigationDuration: 0,
	fuelConsumption: 0
};

export default {
	name: 'CarbonTemplateDialog',
	components: { CoTwoClap, SupplierSelector, CurrencySvg, InfoTooltip, CustomTextArea },

	mixins: [languageMessages, globalMixin],

	props: {
		open: { type: Boolean, default: false, required: false },
		dataToEdit: { type: Object, default: () => ({}), required: false },
		isCopyAction: { type: Boolean, default: false, required: false },
		rawTextData: { type: Object, default: () => ({}), required: false }
	},

	data() {
		return {
			dataToSend: _.cloneDeep(INIT_DATA_TO_SEND),
			averageEnergy: 0,
			unitAverageEnergy: 'Kwh',
			averageMeal: 0,

			loadingSubmit: false,
			isEdit: false,
			rawTextFileDownloaded: false,

			locationsPremisesList: [],
			carbonData: _.cloneDeep(INIT_DATA_CARBON),
			refAllUseTextArea: [],
			CUSTOM_TEXT_AREA_REFS: {
				TEXT_AREA_KEYWORD_ONE: 'textarea-keyword-1',
				TEXT_AREA_KEYWORD_TWO: 'textarea-keyword-2',
				TEXT_AREA_KEYWORD_THREE: 'textarea-keyword-3'
			}
		};
	},

	watch: {
		dataToEdit: {
			async handler(newVal) {
				let newData = {};
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					newData = _.cloneDeep(newVal);
					newData.productionPhase = +newVal.productionPhase;
					newData.typeMeasurement = newVal.typeMeasurement;
					newData.navigationDuration = newVal.navigationDuration;
					newData.fuelConsumption = newVal.fuelConsumption;
					newData.boolValue = typeof newData.boolValue === 'number' ? newData.boolValue === 1 : newData.boolValue;
					if (newVal.numberOfVehicule) {
						newData.numberOfVehicule = newVal.numberOfVehicule;
						newData.number = newVal.number;
					}

					if (newData.coTwoCategory === 3 && newData.subType === 1) {
						this.averageEnergy = newVal.kwh;
					} else if (newData.coTwoCategory === 3 && newData.subType === 2) {
						this.averageEnergy = newVal.mcube;
					} else if (newData.coTwoCategory === 3 && newData.subType === 3) {
						this.averageEnergy = newVal.mcube;
					} else if (newData.coTwoCategory === 3 && newData.subType === 4) {
						this.averageEnergy = newVal.liter;
					} else if (newData.coTwoCategory === 3 && newData.subType === 5) {
						this.averageEnergy = newVal.mcube;
					} else if (newData.coTwoCategory === 3 && newData.subType === 6) {
						this.averageEnergy = newVal.kg;
					}

					if (newData.averageElec) {
						newData.unitAmountTotal = !_.isFinite(newData.averageElec) ? 0 : parseFloat(newData.averageElec).toFixed(2);
					}

					if (newData.coTwoCategory === 5 && newData.type === 1 && newData.averageWasteCardboard) {
						newData.unitAmountTotal = parseFloat(newVal.averageWasteCardboard).toFixed(2);
					}

					if (newData.coTwoCategory === 5 && newData.type === 2 && newData.averageWastePaint) {
						newData.unitAmountTotal = parseFloat(newVal.averageWastePaint).toFixed(2);
					}

					if (newData.averageMealVege) {
						newData.unitAmountTotal = parseFloat(newVal.averageMealVege).toFixed(2);
					}

					newData.locationId = newData.locationId === 0 ? null : newData.locationId;

					if (newData.coTwoCategory === 3) {
						if (newData.type === 1) {
							this.locationsPremisesList = await getLocations();
						} else if (newData.type === 2) {
							this.locationsPremisesList = await getProjectLocationListForPremises();
						} else {
							this.locationsPremisesList = [];
						}
					}

					this.dataToSend = { ...newData };

					this.isEdit = true;
				} else {
					this.isEdit = false;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		isOpen: {
			get() {
				this.initializeRefs();
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		showAverageCostPerKilometer() {
			if (this.dataToSend.coTwoCategory === 4) {
				if (this.dataToSend.typeMeasurement === 1) {
					return true;
				} else if (this.dataToSend.typeMeasurement === 2 || this.dataToSend.typeMeasurement === 3) {
					return false;
				}
			}

			return false;
		},
		labelSetCat() {
			const res = this.generateSetCat();
			return `${this.FormMSG(547, 'SETCAT')}(${res.coTwoCategory} ${res.type} ${res.subType} ${res.additionalSubType}),`;
		},
		reasonTypes() {
			return [{ value: 0, text: this.FormMSG(263, 'Please select ...') }, ...this.FormMenu(REASON_ENERGY_TYPE_MENU)];
		},
		subTypeEnergyCaption() {
			if (this.dataToSend.coTwoCategory === 3 && this.dataToSend.type === 1) {
				return this.FormMSG(869, 'Location selection');
			} else if (this.dataToSend.coTwoCategory === 3 && this.dataToSend.type === 2) {
				return this.FormMSG(870, 'Premise selection');
			}

			return '';
		},
		showSubTypeEnergy() {
			if (this.dataToSend.coTwoCategory === 3 && [1, 2].includes(this.dataToSend.type)) {
				return true;
			}

			return false;
		},
		showRawTextEditor() {
			if (JSON.stringify(this.rawTextData) !== '{}') {
				return true;
			}

			return false;
		},
		titleCaption() {
			if (this.isCopyAction === false) {
				if (this.isEdit === false) {
					return this.FormMSG(45, 'New automation');
				} else if (this.isEdit === true) {
					return this.FormMSG(98, 'Edit automation');
				}
			} else {
				return this.FormMSG(821, 'Copy automation');
			}
		},
		mealTypeOptions() {
			return this.FormMenu(MEAL_TYPE_MENU);
		},
		energyTypeOptions() {
			if (this.dataToSend.type === 1 || this.dataToSend.type === 2) {
				return this.FormMenu(ENERGY_TYPE_MENU);
			} else if (this.dataToSend.type === 3) {
				return this.FormMenu(CAR_LODGE_ENERGY_TYPE_MENU);
			}

			return this.FormMenu(ENERGY_TYPE_MENU);
		},
		wasteTypeOptions() {
			if (this.dataToSend.coTwoCategory === 5 && this.dataToSend.type === 1) {
				return this.FormMenu(WASTE_STANDARD_TYPE_MENU);
			} else if (this.dataToSend.coTwoCategory === 5 && this.dataToSend.type === 2) {
				return this.FormMenu(WASTE_DESTRUCTION_TYPE_MENU);
			}

			return [];
		},
		vehicleTypeOptions() {
			return this.FormMenu(11011);
		}
	},
	created() {
		this.initializeRefs();
	},
	methods: {
		initializeRefs() {
			const refsItem = Object.values(this.CUSTOM_TEXT_AREA_REFS);
			const ArrayRefsTextArea = [...refsItem];
			this.refAllUseTextArea = ArrayRefsTextArea.map((text) => {
				const refsExist = this.$refs[text];
				if (refsExist) {
					return {
						name: text,
						refs: refsExist,
						isShowContextMenu: refsExist.isShowContextMenu
					};
				}
				return {
					name: text,
					refs: null,
					isShowContextMenu: false
				};
			});
		},
		handleSetModelValue(item) {
			if (!_.isNil(item)) {
				const { dataTextArea, keyItem } = item;
				this.dataToSend[keyItem] = dataTextArea;
			}
		},
		useSetCatInKeyword(field) {
			if (this.dataToSend[field] === '') {
				this.dataToSend[field] += this.labelSetCat;
			} else {
				this.dataToSend[field] += `\n${this.labelSetCat}`;
			}
		},
		generateSetCat() {
			let type = 0;
			let subType = 0;
			let additionalSubType = 0;
			let label = '';

			if (this.carbonData.coTwoCategory === 0) {
				type = 0;
				subType = 0;
				additionalSubType = 0;
				label = '';
			} else if (this.carbonData.coTwoCategory === 1) {
				type = 0;
				subType = 0;
				additionalSubType = 0;
			} else if (this.carbonData.coTwoCategory === 2) {
				type = !_.isNil(this.carbonData.type) ? this.carbonData.type : 0;
				subType = 0;
				additionalSubType = 0;

				if (this.carbonData.type === 294) {
					label = this.FormMSG(875, 'Apartment');
				} else if ([295, 296].includes(this.carbonData.type)) {
					label = this.FormMSG(876, 'House');
				} else if ([290, 291, 292, 293].includes(this.carbonData.type)) {
					label = this.FormMSG(877, 'Hotel');
				}
			} else if (this.carbonData.coTwoCategory === 3) {
				type = this.dataToSend.type;
				subType = this.dataToSend.subType;
				additionalSubType = 0;

				if (this.carbonData.type === 1 || this.carbonData.type === 2) {
					label = this.GetTextFromMenuNumberAndMenuValue(ENERGY_TYPE_MENU, this.dataToSend.subType);
				} else if (this.carbonData.type === 3) {
					label = this.GetTextFromMenuNumberAndMenuValue(CAR_LODGE_ENERGY_TYPE_MENU, this.dataToSend.subType);
				}
			} else if (this.carbonData.coTwoCategory === 4) {
				type = !_.isNil(this.carbonData.type) ? this.carbonData.type : 0;
				subType = !_.isNil(this.carbonData.subType) ? this.carbonData.subType : 0;
				additionalSubType = !_.isNil(this.carbonData.additionalSubType) ? this.carbonData.additionalSubType : 0;

				if ([1, 2, 3].includes(type)) {
					label = this.FormMSG(1010, 'Boat');
				} else if ([17, 18, 19, 20, 21].includes(type)) {
					label = this.FormMSG(1011, 'Freight');
				} else if ([4, 5].includes(type)) {
					label = this.FormMSG(1012, 'Bus');
				} else if ([6, 7, 8].includes(type)) {
					label = this.FormMSG(1013, 'Car');
				}
			} else if (this.carbonData.coTwoCategory === 5) {
				type = this.carbonData.type;
				subType = 0;
				additionalSubType = 0;

				label = this.FormMSG(1001, 'Waste');
			} else if (this.carbonData.coTwoCategory === 6) {
				type = this.dataToSend.subType;
				subType = 0;
				additionalSubType = 0;

				label = this.GetTextFromMenuNumberAndMenuValue(MEAL_TYPE_MENU, this.dataToSend.subType);
			} else if (this.carbonData.coTwoCategory === 7) {
				type = this.carbonData.type;
				subType = 0;
				additionalSubType = 0;

				label = this.GetTextFromMenuNumberAndMenuValue(11008, this.carbonData.type);
			} else if (this.carbonData.coTwoCategory === 8) {
				type = this.carbonData.type;
				subType = 0;
				additionalSubType = 0;

				label = this.GetTextFromMenuNumberAndMenuValue(11009, this.carbonData.type);
			} else if (this.carbonData.coTwoCategory === 9) {
				type = this.carbonData.type;
				subType = 0;
				additionalSubType = 0;

				label = this.GetTextFromMenuNumberAndMenuValue(11010, this.carbonData.type);
			} else if (this.carbonData.coTwoCategory === 10) {
				type = this.dataToSend.type;
				subType = 0;
				additionalSubType = 0;

				label = this.GetTextFromMenuNumberAndMenuValue(11011, type);
			} else if (this.carbonData.coTwoCategory === 12) {
				type = 0;
				subType = 0;
				additionalSubType = 0;

				label = this.FormMSG(1005, 'Car-lodges');
			} else if (this.carbonData.coTwoCategory === 13) {
				type = this.carbonData.type;
				subType = 0;
				additionalSubType = 0;

				label = this.GetTextFromMenuNumberAndMenuValue(11012, type);
			} else if (this.carbonData.coTwoCategory === 14) {
				type = this.carbonData.type;
				subType = 0;
				additionalSubType = 0;

				label = this.GetTextFromMenuNumberAndMenuValue(CARBON_PRODUCTION_LOGISTIC_TYPE, type);
			} else if (this.carbonData.coTwoCategory === 15) {
				type = this.dataToSend.subType;
				subType = 0;
				additionalSubType = 0;

				if (this.carbonData.type === 1 || this.carbonData.type === 2) {
					label = this.GetTextFromMenuNumberAndMenuValue(ENERGY_TYPE_MENU, type);
				} else if (this.carbonData.type === 3) {
					label = this.GetTextFromMenuNumberAndMenuValue(CAR_LODGE_ENERGY_TYPE_MENU, type);
				}
			}

			return {
				coTwoCategory: this.carbonData.coTwoCategory,
				type,
				subType,
				additionalSubType,
				label
			};
		},
		async handleChangeReason(payload) {
			this.dataToSend.locationId = null;
			this.dataToSend.subType = 1;

			if (payload === 1) {
				this.locationsPremisesList = await getLocations();
			} else if (payload === 2) {
				this.locationsPremisesList = await getProjectLocationListForPremises();
			} else {
				this.locationsPremisesList = [];
			}
		},
		handleCopyRawText() {
			const textToCopy = this.rawTextData.text;

			navigator.clipboard.writeText(textToCopy);

			const blob = new Blob([textToCopy], { type: 'text/plain' });
			const url = window.URL.createObjectURL(blob);

			const a = document.createElement('a');
			a.href = url;
			a.download = 'example.txt';
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);

			this.rawTextFileDownloaded = true;
		},
		handleChangeAverageCostPerNight() {
			this.dataToSend.number = 1;
			this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.unitAmountTotal);
		},
		handleChangeWasteType(payload) {
			this.dataToSend.number = 1;
			if (this.dataToSend.type === 1) {
				if (!_.isNil(this.dataToSend.averageWasteCardboard) && payload === 1) {
					this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWasteCardboard)
						? 0
						: parseFloat(this.dataToSend.averageWasteCardboard).toFixed(2);
				} else if (!_.isNil(this.dataToSend.averageWasteFood) && payload === 4) {
					this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWasteFood)
						? 0
						: parseFloat(this.dataToSend.averageWasteFood).toFixed(2);
				} else if (!_.isNil(this.dataToSend.averageWasteGeneral) && payload === 5) {
					this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWasteGeneral)
						? 0
						: parseFloat(this.dataToSend.averageWasteGeneral).toFixed(2);
				} else if (!_.isNil(this.dataToSend.averageWastePaper) && payload === 2) {
					this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWastePaper)
						? 0
						: parseFloat(this.dataToSend.averageWastePaper).toFixed(2);
				} else if (!_.isNil(this.dataToSend.averageWastePlastic) && payload === 3) {
					this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWastePlastic)
						? 0
						: parseFloat(this.dataToSend.averageWastePlastic).toFixed(2);
				} else if (!_.isNil(this.dataToSend.averageWasteBatterie) && payload === 6) {
					this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWasteBatterie)
						? 0
						: parseFloat(this.dataToSend.averageWasteBatterie).toFixed(2);
				}
			} else if (this.dataToSend.type === 2) {
				if (!_.isNil(this.dataToSend.averageWastePaint) && payload === 1) {
					this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWastePaint)
						? 0
						: parseFloat(this.dataToSend.averageWastePaint).toFixed(2);
				} else if (!_.isNil(this.dataToSend.averageWasteCardboard) && payload === 4) {
					this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWasteCardboard)
						? 0
						: parseFloat(this.dataToSend.averageWasteCardboard).toFixed(2);
				} else if (!_.isNil(this.dataToSend.averageWasteWood) && payload === 5) {
					this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWasteWood)
						? 0
						: parseFloat(this.dataToSend.averageWasteWood).toFixed(2);
				} else if (!_.isNil(this.dataToSend.averageWasteSteel) && payload === 2) {
					this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWasteSteel)
						? 0
						: parseFloat(this.dataToSend.averageWasteSteel).toFixed(2);
				} else if (!_.isNil(this.dataToSend.averageWastePlastic) && payload === 3) {
					this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWastePlastic)
						? 0
						: parseFloat(this.dataToSend.averageWastePlastic).toFixed(2);
				}
			}
		},
		handleChangeAverageTransport() {
			this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.unitAmountTotal);
			this.dataToSend.km = 1;
		},
		handleChangeAverateFuel() {
			this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.unitAmountTotal);
			this.dataToSend.liter = 1;
		},
		handleChangeAverageTransportLodges() {
			this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.unitAmountTotal);
			this.dataToSend.km = 1;
		},
		handleChangeAverateVehicleShooting() {
			this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.unitAmountTotal);
			this.dataToSend.km = 1;
		},
		handleChangeMealType(payload) {
			this.dataToSend.mealRed = 0;
			this.dataToSend.mealWhite = 0;
			this.dataToSend.mealVege = 0;
			this.dataToSend.mealNotSpecified = 0;

			if (!_.isNil(this.dataToSend.averageMealVege) && payload === 1) {
				this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.averageMealVege).toFixed(2);
			} else if (!_.isNil(this.dataToSend.averageMealRed) && payload === 2) {
				this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.averageMealRed).toFixed(2);
			} else if (!_.isNil(this.dataToSend.averageMealWhite) && payload === 3) {
				this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.averageMealWhite).toFixed(2);
			} else if (!_.isNil(this.dataToSend.averageMealNotSpecified) && payload === 4) {
				this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.averageMealNotSpecified).toFixed(2);
			}
		},
		handleChangeAverageMeal() {
			this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.unitAmountTotal);
			if (this.dataToSend.subType === 1) {
				this.dataToSend.mealVege = 1;
			} else if (this.dataToSend.subType === 2) {
				this.dataToSend.mealRed = 1;
			} else if (this.dataToSend.subType === 3) {
				this.dataToSend.mealWhite = 1;
			} else if (this.dataToSend.subType === 4) {
				this.dataToSend.mealNotSpecified = 1;
			}
		},
		handleChangeAverageWaste() {
			this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.unitAmountTotal);
			this.dataToSend.number = 1;
		},
		handleChangeEnergyType() {
			this.dataToSend.kwh = 0;
			this.dataToSend.mcube = 0;
			this.dataToSend.liter = 0;
			this.dataToSend.number = 0;
			this.dataToSend.unitAmountTotal = 0;
			this.averageEnergy = 0;
			this.dataToSend.waterVolumeUsed = 0;
			this.dataToSend.kg = 0;

			if (this.dataToSend.averageElec && this.dataToSend.subType === 1) {
				this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageElec) ? 0 : parseFloat(this.dataToSend.averageElec).toFixed(2);
				this.dataToSend.kwh = 1;
			} else if (this.dataToSend.averageGas && this.dataToSend.subType === 2) {
				this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageGas) ? 0 : parseFloat(this.dataToSend.averageGas).toFixed(2);
				this.dataToSend.mcube = 1;
			} else if (this.dataToSend.averageWater && this.dataToSend.subType === 3) {
				this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageWater) ? 0 : parseFloat(this.dataToSend.averageWater).toFixed(2);
				this.dataToSend.mcube = 1;
			} else if (this.dataToSend.averageGeneratorUnlead && this.dataToSend.subType === 4) {
				this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageGeneratorUnlead)
					? 0
					: parseFloat(this.dataToSend.averageGeneratorUnlead).toFixed(2);
				this.dataToSend.liter = 1;
			} else if (this.dataToSend.averageGeneratorGas && this.dataToSend.subType === 5) {
				this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageGeneratorGas)
					? 0
					: parseFloat(this.dataToSend.averageGeneratorGas).toFixed(2);
				this.dataToSend.mcube = 1;
			} else if (this.dataToSend.averageGeneratorHydrogene && this.dataToSend.subType === 6) {
				this.dataToSend.unitAmountTotal = !_.isFinite(this.dataToSend.averageGeneratorHydrogene)
					? 0
					: parseFloat(this.dataToSend.averageGeneratorHydrogene).toFixed(2);
				this.dataToSend.number = 1;
			}
		},
		handleChangeAverageEnergy() {
			this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.unitAmountTotal);
			this.dataToSend.kwh = 0;
			this.dataToSend.mcube = 0;
			this.dataToSend.liter = 0;
			this.dataToSend.number = 0;
			this.dataToSend.waterVolumeUsed = 0;
			this.dataToSend.kg = 0;

			if (this.dataToSend.subType === 1) {
				this.dataToSend.kwh = 1;
			} else if ([2, 5].includes(this.dataToSend.subType)) {
				this.dataToSend.mcube = 1;
			} else if (this.dataToSend.subType === 3) {
				this.dataToSend.waterVolumeUsed = 1;
			} else if (this.dataToSend.subType === 4) {
				this.dataToSend.liter = 1;
			} else if (this.dataToSend.subType === 6) {
				this.dataToSend.kg = 1;
			}
		},
		addEnergyValueField() {
			this.dataToSend.kwh = 0;
			this.dataToSend.mcube = 0;
			this.dataToSend.liter = 0;
			this.dataToSend.waterVolumeUsed = 0;

			if (this.dataToSend.type === 1 || this.dataToSend.type === 2) {
				if (this.dataToSend.subType === 1) {
					this.dataToSend.kwh = 1;
				} else if (this.dataToSend.subType === 2) {
					this.dataToSend.mcube = 1;
				} else if (this.dataToSend.subType === 3) {
					this.dataToSend.waterVolumeUsed = 1;
				} else if (this.dataToSend.subType === 4) {
					this.dataToSend.liter = 1;
				} else if (this.dataToSend.subType === 5) {
					this.dataToSend.mcube = 1;
				} else if (this.dataToSend.subType === 6) {
					this.dataToSend.kg = 1;
				}
			} else if (this.dataToSend.type === 3) {
				if (this.dataToSend.subType === 1) {
					this.dataToSend.liter = 1;
				} else if (this.dataToSend.subType === 2) {
					this.dataToSend.liter = 1;
				} else if (this.dataToSend.subType === 3) {
					this.dataToSend.mcube = 1;
				}
			}
		},
		async handleOk() {
			const actionForLoader = async () => {
				this.loadingSubmit = true;

				this.dataToSend.unitAmountTotal = parseFloat(this.dataToSend.unitAmountTotal);
				this.dataToSend.userId = store.userID();
				this.dataToSend.locationId = this.dataToSend.locationId === null ? 0 : +this.dataToSend.locationId;

				let carbonTemplateId = 0;
				if (this.isCopyAction === false) {
					carbonTemplateId = this.isEdit === false ? 0 : +this.dataToEdit.id;
				}

				if (this.dataToSend.coTwoCategory === 3) {
					this.addEnergyValueField();
				}
				// const carbonTemplateId = this.isEdit === false ? 0 : +this.dataToEdit.id;
				await addUpdCarbonTemplate(carbonTemplateId, {
					..._.omit(this.dataToSend, [
						'averageWasteCardboard',
						'averageWastePaper',
						'averageWastePlastic',
						'averageWasteFood',
						'averageWasteGeneral',
						'averageElec',
						'averageGas',
						'averageWater',
						'averageGeneratorUnlead',
						'averageGeneratorGas',
						'averageGeneratorHydrogene',
						'averageTransportation',
						'averageMealVege',
						'averageMealRed',
						'averageMealWhite',
						'averageMealNotSpecified',
						'averageWastePaint',
						'averageWasteWood',
						'averageWasteSteel',
						'supplier'
					])
				});

				this.loadingSubmit = false;

				let msgSuccess = '';
				if (this.isCopyAction === false) {
					msgSuccess = this.isEdit ? this.FormMSG(950, 'Data updated successfully!') : this.FormMSG(951, 'Data created successfully!');
				} else {
					msgSuccess = this.FormMSG(874, 'Copy successfully!');
				}

				this.createToastForMobile(this.FormMSG(94, 'Success'), msgSuccess);
				this.$emit('carbon-template:submit-success');
				this.emitEventClose();
			};

			await this.showLoader(actionForLoader, 'content-carbon-template-dialog-ref');
		},
		async handleCoTwoClapChange({ carbonData }) {
			this.initDataWhenCoTwoChange();

			this.carbonData = carbonData;
			this.dataToSend.coTwoCategory = carbonData.coTwoCategory;
			this.dataToSend.boolValue = carbonData.boolValue;
			this.dataToSend.type = carbonData.type;
			this.dataToSend.locationId = carbonData.locationId === 0 ? null : carbonData.locationId;

			if ([1, 3, 5, 6].includes(carbonData.coTwoCategory)) {
				this.dataToSend.subType = 1;
				if (carbonData.coTwoCategory === 3) {
					this.dataToSend.reason = 1;

					this.locationsPremisesList = await getLocations();
				}
			} else if (carbonData.coTwoCategory === 10) {
				this.dataToSend.type = 1;
			} else if (carbonData.coTwoCategory === 11) {
				this.dataToSend.subType = carbonData.subType;
			} else if (carbonData.coTwoCategory === 4) {
				this.dataToSend.subType = carbonData.subType;
				this.dataToSend.type = carbonData.type;
				this.dataToSend.additionalSubType = carbonData.additionalSubType;
				this.dataToSend.km = carbonData.km;
				this.dataToSend.reason = carbonData.reason;
				this.dataToSend.vehiculeId = carbonData.vehiculeId;
				this.dataToSend.number = carbonData.number;
				this.dataToSend.numberOfVehicule = carbonData.numberOfVehicule;
				this.dataToSend.productionPhase = carbonData.productionPhase;
				this.dataToSend.typeMeasurement = carbonData.typeMeasurement;
				this.dataToSend.navigationDuration = carbonData.navigationDuration;
				this.dataToSend.fuelConsumption = carbonData.fuelConsumption;
				this.dataToSend.unitAmountTotal = this.dataToSend.unitAmountTotal ? this.dataToSend.unitAmountTotal : 0;
			}
		},
		initDataWhenCoTwoChange() {
			// this.dataToSend.unitAmountTotal = 0;
			this.dataToSend.subType = 0;
			this.averageEnergy = 0;
			this.dataToSend.kwh = 0;
			this.dataToSend.mcube = 0;
			this.dataToSend.liter = 0;
			this.dataToSend.number = 0;
			this.dataToSend.numberOfVehicule = 1;
			this.dataToSend.mealRed = 0;
			this.dataToSend.mealWhite = 0;
			this.dataToSend.mealVege = 0;
			this.dataToSend.mealNotSpecified = 0;
			this.dataToSend.vehiculeId = 0;
			this.dataToSend.reason = 0;
			this.dataToSend.additionalSubType = 0;
			this.dataToSend.productionPhase = 0;
			this.dataToSend.typeMeasurement = 1;
			this.dataToSend.navigationDuration = 0;
			this.dataToSend.fuelConsumption = 0;
		},
		handleSupplierChange(payload) {
			if (payload) {
				this.dataToSend.supplierId = payload.id;
			} else {
				this.dataToSend.supplierId = 0;
			}
		},
		emitEventClose() {
			this.dataToSend = _.cloneDeep(INIT_DATA_TO_SEND);
			this.rawTextFileDownloaded = false;
			this.$emit('carbon-template-dialog:close');
		}
	}
};
</script>
