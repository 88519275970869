var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column w-100" }, [
    _c(
      "div",
      [
        _c(
          "b-form-group",
          { attrs: { label: _vm.label } },
          [
            _c("b-form-textarea", {
              ref: _vm.refTextArea,
              attrs: {
                id: _vm.id,
                placeholder: _vm.placeholder,
                rows: _vm.rows,
                "max-rows": _vm.maxRows,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.handleClick.apply(null, arguments)
                },
                keyup: _vm.handleKeyUp,
              },
              model: {
                value: _vm.dataTextArea,
                callback: function ($$v) {
                  _vm.dataTextArea = $$v
                },
                expression: "dataTextArea",
              },
            }),
          ],
          1
        ),
        _c("context-menu-custom-for-text-area", {
          attrs: {
            menuX: _vm.menuX,
            menuY: _vm.menuY,
            showContextMenu: _vm.isShowContextMenu,
            title: "Variables",
            dataList: _vm.variableList,
          },
          on: {
            "on-close-context-menu": _vm.CloseContextMenu,
            "item:clicked": _vm.selectedArea,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }